/* General styling */

.login-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0; /* Remove padding for full screen */
  margin: 0; /* Remove margin for full screen */
}

.dataColumn {
  display: block;
}

.loginColumn {
  display: block;
}

/* Mobile-specific styling */
@media (max-width: 576px) {
  .dataColumn {
    display: none; /* Hide the data column on small screens */
  }

  .login-page-container {
    padding: 0; /* Ensure no padding for mobile */
  }

  .loginColumn {
    width: 100%;
    margin: 0;
    padding: 0; /* Make login column take full width */
  }

  .btn {
    padding: 10px;
    font-size: 14px;
  }

  .maindiv, .subdiv {
    margin: 0;
    padding: 0;
  }
}

.maindiv, .subdiv {
  margin: 50px;
  padding: 50px;
}
@media (max-width: 430px) {
  
  .maindiv, .subdiv {
    margin: 15px;
    padding: 0px;
  
    height: 100vh;
  }
}
.input-box {
  border-radius: 50px;
  border: 2px solid ; /* Default border */

 
}

