.navdash {
    position: fixed;
    top: 20px;
    left: 20px;
    bottom: 20px;
    width: 250px; /* Increased the width */
    border-radius: 20px;
    box-sizing: initial;
    border-left: 5px solid #236a80;
    background: #236a80;
    overflow-x: hidden;
}

.navdash ul {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%; /* Adjust to 100% of the nav's width */
    padding-left: 5px;
    padding-top: 75px;
}

.navdash li:hover a,
.navdash li:hover a span {
    color: black; 
}

.menu {
    background: #236a80;
    padding: 0px 0 40px;
    list-style-type: none;
    width: 100%; /* Set width to 100% of nav */
    position: absolute;   
}

.menu > li {
    padding: 15px;
    cursor: pointer;
    position: relative;
}

.menu > li:hover {
    background: #ffff;
    border-radius: 60px 0 0 60px;
    color: black;
    width: 100%; /* Adjust this to make sure the hover stays inside the nav */
    /* Smooth transition when width changes */
   
}

.menu > li > a {
    padding: 10px 15px;
    font-family: 'Source Sans Pro', sans-serif;
     /* Default link color */
    text-decoration: none; 
    
}

.menu > li:hover > a {
    color: black; /* Change text color on hover */
}

.menu > li:hover::before, 
.menu > li:hover::after {
    content: "";
    position: absolute;
    color: black;
}

.menu > li:hover::before {
    top: -80px;
    right: -1px;
    height: 80px;
    width: 40px;
    border-bottom-right-radius: 80px;
    box-shadow: 0 40px 0 0 #ffff;
}

.menu > li:hover::after {
    top: 50px;
    right: -1px;
    height: 80px;
    width: 40px;
    border-top-right-radius: 80px;
    box-shadow: 0 -40px 0 0 #ffff;
}
.fontstyle{
    font-family: Sukar Black;
}

  @media only screen and (max-width: 768px) {
    .dashboard-sam {
      display: block;
    }
  }
  
/*.nav ul {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-left: 5px;
    padding-top: 75px;
}

.nav li {
    position: relative;
    display: block;
    width: 100%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: -50px;
    padding: 15px 15px 15px;
    margin-bottom: 10px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


.nav li:hover {
    background-color: white; 
    transform: translateX(10px);
    color: black;
    
}
.nav li:hover a,
.nav li:hover a span {
    color: black; 
} */

 
 /* .nav {
    flex-direction: column;
    position: fixed;
    top: 20px;
    left: 20px;
    bottom: 20px;
    width: 125px;
    height: 99%;


    border-radius: 20px;
    box-sizing: initial;
    border-left: 5px solid#236a80;
    background: #236a80;
    transition: 0.5s;
    overflow-x: hidden;
}
.nav a{
    position: relative;
    top: 20px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    width: 80%;
    height: 50px;
    text-align: center;
    color: aliceblue;
    float: right;
    border-radius: 10px 0  0 10px;
    margin-top: 20px;
    line-height: 50p;
    transition: color .5s;

}
.nav a:hover{
    color: #2a2a2a;
    background: white;
}
.nav a::after, .menu a::before{
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    width: 20px;
    height: 50px;
    border-radius: 0 25px 0 0;
    z-index: -1;
    background: red;

}
.nav a::before{
    top: auto;
    bottom: -50px;
    border-radius: 0 25px 0 0 ;
}
.nav a:hover::after{
    box-shadow: 0 25px 0 0 white;
}
.nav a:hover::before{
    box-shadow: 0 -25px 0 0 white;
} */