.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
 
}

.popup {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5);
  max-width: 500px;
  width: 100%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.close-btn .icon-cross {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #ff4d4f; /* Red cross color */
  border-radius: 50%;
  line-height: 16px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.close-btn .visually-hidden {
  display: none;
}

.calibration-details {
  margin-top: 20px;
}

.card-text {
  margin: 10px 0;
  font-size: 16px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff; /* Solid white background */
  padding: 20px;
  border-radius: 10px;
  width: 50%; /* Set the modal width */
  max-width: 600px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  color: #000; /* Black text color for readability */
}

.modal-content h5 {
  margin-bottom: 15px;
  color: #000; /* Ensure the header text is black */
}

textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  background-color: #fff; /* White background for textarea */
  color: #000; /* Black text for textarea */
}

.modal-actions {
  display: flex;
  justify-content: start;
  margin-top: 15px;
}

.modal-actions .btn-success {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-actions .btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
