.pie-chart-card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pie-chart-header {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding: 25px;
}

.pie-chart-container {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pie-chart-text {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 12px;
    color: #4E5D6C;
}
