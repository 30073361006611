aside {
  padding: 10px;
}

.shapes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dndnode {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Adding border and making the sidebar scrollable */
.sidebar-container {
border: 2px solid #236a80;
padding: 10px;
height: 100vh; /* Full height */
overflow-y: auto; /* Make it scrollable */
box-sizing: border-box; /* Ensure padding is included in the width/height */
}
.react-flow-container {
border: 2px solid #236a80; /* Add border with the required color */
padding: 10px;
height: 100vh; /* Adjust the height as needed */
width: 100%; /* Full width */
overflow: hidden; /* Ensure React Flow is inside the container */
position: relative; /* Important to keep elements inside */
border-radius:10px ;
}

.react-flow-scrollable {
height: 100%; 
overflow: auto; /* Make the container scrollable if content exceeds */
}
.delete-box {
background-color: white;
border: 1px solid #ccc;
padding: 10px;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}