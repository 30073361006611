/* Add the styles in a separate CSS file */
.table-container {
    width: 100%;
    margin: 20px auto;
}
.report-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.report-table th, .report-table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
}
.report-table th {
    background-color: #236a80;
    color: white;
}
.section-header {
    background-color: #d9ead3;
    font-weight: bold;
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
}
.highlight-orange {
    background-color: #f9cb9c;
}
.highlight-green {
    background-color: #b6d7a8;
}
.time-row {
    font-size: 10px;
    text-align: center;
    color: #666;
}

.timestamp p {
    margin: 0;
    padding: 0;
}
