/* Offcanvas full-screen settings */
.full-screen-offcanvas .offcanvas {
  width: 100%; /* Full width on mobile */
  height: 100%; /* Full height on mobile */
  max-width: 100%; /* Override Bootstrap's max-width */
  padding-left: 0; /* Remove left padding */
  padding-right: 0; /* Remove right padding */
}
.full-screen-offcanvas .offcanvas-body {
  padding: 0; /* Remove padding to make use of full screen */
}

@media only screen and (min-width: 1200px) {
  .full-screen-offcanvas .offcanvas {
    width: 300px; /* Set width for larger screens */
    height: auto; /* Default height for larger screens */
  }
}

/* Navbar styles */
/* Header styles */
.header-navbar {
 
   /* Optional: adds shadow for better visibility */
    
  /*  position: fixed; 
   top: 0; 
   z-index: 1000;
   background: white; */ /* Set a background color to ensure text doesn't blend in with content below */
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; /* Optional: adds shadow for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
 width: 72%;
  border-radius: 10px;
}

.brand-text {
  font-size: 1.2rem;
}

@media (max-width: 1050px) {
  .header-navbar {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    width: 95%;
  }
  
  /* Add margin-top for the dropdown in mobile view */
  
  
  @media (min-width: 769px) {
    .header-navbar .icons{
      margin-left: 0;
    margin-right: 0;
   
    padding-right: 0;

    }
  
    
  }

  .full-screen-offcanvas .offcanvas {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .full-screen-offcanvas .offcanvas-body {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .header-navbar .d-flex {
    justify-content: flex-end;
  }

  .header-navbar .brand-text {
    text-align: left;
  }
}
@media (max-width: 450px) {
  .drop {
    margin-top: 20%;
    background: none;
  }
}

/* Desktop and larger screens (min-width: 768px) */
@media (min-width: 768px) {
  .drop {
    margin-top: 10%;
    background: none;
  }
}
