.last-trips-card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjust the width to decrease the size */
    margin: 0 auto;
    padding: 0px; /* Optional: Adjust padding if needed */
}

.last-trips-card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding-bottom: 0;
}

.last-trips-card .card-header h5 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.last-trips-card .card-header p {
    font-size: 0.9rem;
    margin-bottom: 0;
}

.last-trips-card .table thead th {
    font-size: 0.875rem;
    color: #6c757d;
    font-weight: 500;
}

.last-trips-card .table tbody tr {
    border-bottom: 1px dotted #1c1c1d; /* Change solid border to dotted */
}

.last-trips-card .table tbody tr:last-child {
    border-bottom: none;
}

.last-trips-card .table tbody td {
    padding: 0.75rem 0.5rem;
    vertical-align: middle;
}

.last-trips-card .badge {
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-block;
}

/* Force table layout to respect width settings */
.last-trips-card .table {
   table-layout:inherit;
    width: 100%;
}

.custom-width {
    min-width: 150px; /* Adjust the minimum width as needed */
    white-space: nowrap; /* Prevents text from wrapping */
}
.customwidth{
    min-width: 100px; /* Adjust the minimum width as needed */
    white-space: nowrap; 
}
.search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns content to the left */
    flex-wrap: nowrap; /* Prevents wrapping */
}

.search-input {
    flex-grow: 1; /* Makes the input field grow to fill available space */
}

.search-button {
    flex-shrink: 0; /* Prevents the button from shrinking */
}

/* table */
/* Table Container */
.custom-table-responsive {
    padding: 20px;
    background-color: #f7f8fa;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow */
    margin-bottom: 20px;
  }
  
  /* Table Styles */
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: rgba(245, 245, 245, 0.9); /* Smoky white background */
    border-radius: 10px;
    overflow: scroll; /* Ensure rounded corners */
  }
  
  .custom-table th,
  .custom-table td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #dee2e6; /* Row separators */
  }
  
  .custom-table-header {
    background-color: #f8f9fa; /* Light gray header */
  }
  
  .custom-table-row:nth-child(even) {
    background-color: rgba(250, 250, 250, 0.8); /* Subtle alternate row color */
  }
  
  .custom-table-row:hover {
    background-color: rgba(240, 240, 240, 0.8); /* Highlight on hover */
  }
  
  .custom-no-data {
    font-size: 16px;
    font-weight: bold;
    color: #6c757d;
  }
  
  /* Pagination Styles */
  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .custom-pagination-button {
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .custom-pagination-info {
    font-size: 16px;
    font-weight: 500;
  }
  

  /* Container for horizontal scrolling */
.custom-table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
  margin-bottom: 15px; /* Add spacing below the table */
}

.custom-table {
  width: 100%; /* Ensure the table takes full width */
  border-collapse: collapse;
  text-align: left;
}

.custom-table-header th {
  background-color: #f1f1f1;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ddd;
}

.custom-table-row td {
  padding: 8px;
  border: 1px solid #ddd;
}

.custom-no-data {
  padding: 15px;
  font-style: italic;
  color: #888;
}

/* Pagination controls */
.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.custom-pagination-button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
}

.custom-pagination-info {
  font-size: 14px;
  margin: 0 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-table-container {
    overflow-x: auto; /* Ensures horizontal scrolling on small screens */
  }

  .custom-pagination-info {
    font-size: 12px; /* Reduce font size for pagination info */
  }
}
