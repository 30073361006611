
.dashboard-header {
  margin-left: 0; /* Default for mobile/tablet */
}

@media (min-width: 1024px) {
  .dashboard-header {
    margin-left: 150px; /* For laptops and larger screens */
  }
}

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .stack-box {
    padding: 15px;
    margin-top: 10px;
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  /* Full height card styling */
  .full-height-card {
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
  }
  
  /* Top section styling */
  .top-section {
  height: 80px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Inner cards container for side-by-side layout */
  .two-cards-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  }
  
  /* Inner cards styling */
  .inner-card {
  width: 48%;
  height: 400px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  }
  
  /* Hover effect for inner cards */
  .inner-card:hover {
  transform: scale(1.02);
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
  .two-cards-container {
    flex-direction: column;
  }
  
  .inner-card {
    width: 100%;
    margin-bottom: 10px;
  }
  }
  .pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 10px;
  }
  
  .pagination li {
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  }
  
  .pagination .active {
  background-color: #007bff;
  color: white;
  }
  .energy-flow-container {
    padding: 20px;
  }
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .stack-box {
   
    padding: 15px;
    margin-top: 10px;
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  /* Full height card styling */
  .full-height-card {
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
  }
  
  /* Top section styling */
  .top-section {
  height: 80px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Inner cards container for side-by-side layout */
  .two-cards-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  }
  
  /* Inner cards styling */
  .inner-card {
  width: 48%;
  height: 400px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  }
  
  /* Hover effect for inner cards */
  .inner-card:hover {
  transform: scale(1.02);
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
  .two-cards-container {
    flex-direction: column;
  }
  
  .inner-card {
    width: 100%;
    margin-bottom: 10px;
  }
  }
  .pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 10px;
  }
  
  .pagination li {
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  }
  
  .pagination .active {
  background-color: #007bff;
  color: white;
  }
  
  /* consuption cards */
  .energy-flow-container {
   /*  border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 20px;
   
    display: flex;
   
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
  .energy-flow-item {
    width: 250px;
    margin: 30px;
  }
  @media (max-width:658px) {
    .popup-title
   {
     margin-top: 70px;

   }
   
 }
 .chart-wrapper {
  width: 100%;
  height: 100%;
}
